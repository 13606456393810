<template>
  <div>
    <b-card>
      <div slot="header">
        <strong>할인율설정 검색 </strong>
      </div>
      <div>
        <b-button class="ml-1 mb-1" variant="outline-success" v-for="s in shop" v-if="s.pageCnt" size="sm" @click="selectedShop = s; getShop()">{{s.shop_id}}. {{s.boutique}}</b-button>
      </div>
      <b-row>
        <b-col cols="8">
          <v-select v-model="selectedShop" :options="shop" placeholder="SHOP을 선택해주세요" @input="getShop"></v-select>
        </b-col>
        <b-col>
          <label v-if="selectedShop && stat" class="col-form-label">
            상품수: <b-badge variant="light">{{stat.goodsCount}}</b-badge>,
            규칙 페이지 수: <b-badge variant="light">{{selectedShop.pageCnt || 0}}</b-badge>
            총 규칙 수: <b-badge variant="light">{{selectedShop.ruleCnt || 0}}</b-badge>
          </label>
        </b-col>
      </b-row>
      <b-form v-if="selectedShop">
        <div class="mt-2" v-if="season.length">
          <b-tabs>
            <b-tab v-for="(s,i) in season" :key="i" :title="s.value || '(빈문자)'" @click="setTimeout(e=>$refs[`hotTable_${s.value}`][0].hotInstance.render(), 0)">
              <hot-table :ref="`hotTable_${s.value}`" :key="`${selectedShop.shop_id}_${s.value}`" :settings="hotSettings[s.value]"></hot-table>
            </b-tab>
          </b-tabs>
        </div>

        <b-collapse id="collapse" class="mt-2" v-model="collapse">
          <b-row class="mb-3">
            <b-col cols="12" lg="4">
              <div class="clearfix">
                <label class="mt-1">카테고리<span v-if="category.length">: {{category.length}}개</span></label>
                <div class="pull-right">
                  <b-form-checkbox v-if="selectedShop" v-model="havingCategoryOnly" name="check-button" inline @update:indeterminate="setStat">
                    존재하는 카테고리만
                  </b-form-checkbox>
                  <b-button class=" btn-sm m-1" variant="warning" @click="form.category = []">초기화</b-button>
                </div>
              </div>
              <v-select v-model="form.category" multiple :options="category" placeholder="전체 카테고리"></v-select>
            </b-col>
            <b-col cols="12" lg="4">
              <div class="clearfix">
                <label class="mt-1">브랜드<span v-if="brand.length">: {{brand.length}}개</span></label>
                <div class="pull-right">
                  <b-form-checkbox v-if="selectedShop" v-model="havingBrandOnly" name="check-button" inline @update:indeterminate="setStat">
                    존재하는 브랜드만
                  </b-form-checkbox>
                  <b-button class="btn-sm m-1" variant="warning" @click="form.brand = []">초기화</b-button>
                </div>
              </div>
              <v-select v-model="form.brand" multiple :options="brand" placeholder="전체 브랜드"></v-select>
            </b-col>
            <b-col cols="12" lg="4">
              <div class="clearfix">
                <label class="mt-1">시즌<span v-if="season.length">: {{season.length}}개</span></label>
                <b-button class="pull-right btn-sm m-1" variant="warning" @click="form.season = []">초기화</b-button>
              </div>
              <v-select v-model="form.season" multiple :options="season" placeholder="전체 시즌"></v-select>
            </b-col>
          </b-row>
          <div class="mb-3">
            <label class="mt-1">GoodsId</label>
            <b-form-textarea :rows="2" v-model.trim="form.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
            <small class="text-muted">엔터로 구분된 goods_id를 입력해주세요</small>
          </div>
          <b-row class="mb-3">
            <b-col cols="4">
              <label class="mt-1">할인율</label>
              <b-form inline>
                <b-input type="text" v-model.number="form.rate"></b-input> %
              </b-form>
            </b-col>
          </b-row>
          <div class="mt-2 clearfix">
            <b-button class="m-1" variant="success" v-if="selectedShop && stat" @click="addDiscountRule(selectedShop.shop_id)">SHOP 할인율규칙 추가</b-button>
            <b-button class="m-1" variant="warning" @click="resetForm">초기화</b-button>
          </div>
        </b-collapse>
        <div class="mt-2 clearfix">
          <b-button class="m-1" variant="primary" @click="saveTables">저장</b-button>
          <b-button class="m-1" variant="outline-info" v-b-toggle.collapse>커스텀 할인율 규칙 설정</b-button>
          <small>* 저장되지 않은 데이터는 아래에서 페이지를 변경할 때 사라지게 되니 꼭 저장해 주시기 바랍니다</small>
          <div class="pull-right">
            <b-button class="m-1" variant="outline-primary" @click="helpModal = true">도움말</b-button>
            <!--<b-button class="m-1" variant="primary" v-if="selectedShop && stat">규칙에 맞는 상품들 보기</b-button>-->
          </div>
        </div>
      </b-form>
    </b-card>

    <b-modal title="도움말" v-model="helpModal" ok-only ok-title="확인">
      - 각 샵에서 Mapped 정보를 가져와서, 상품이 있는 경우에 대해서만 보여줍니다.<br/>
      - 할인율 규칙 페이지가 없는 경우 꼭 먼저 페이지를 생성하고 시작해주세요.<br/>
      - 할인율규칙은 위에서부터 차례대로 비교해가며 적용합니다. 예를 들어 [MCL,MSH] : 5%, MCL : 8% 순으로 되어있다면 [MCL,MSH]에서 먼저 일치했기 때문에 5% 가 적용됩니다<br/>
    </b-modal>

    <div v-if="selectedShop" class="mt-3">
      <hr />
      <h5>{{selectedShop.value}} 규칙</h5>
      <b-tabs v-model="pageIndex">
        <template v-slot:tabs-end>
          <b-nav-item @click.prevent="newPage" href="#"><b>+</b></b-nav-item>
        </template>
        <b-tab v-for="(p, idx) in rules" :title="`SHOP 규칙 Page ${p.page}`" :active="p.active" :key="p.page">
          <template v-slot:title>
            <span v-if="p.name">{{p.name}}</span>
            <span v-else>SHOP 규칙 Page {{p.page}}</span>
            &nbsp;<b-badge v-if="p.active" variant="primary">ACTIVE</b-badge>
          </template>
          <div class="clearfix mb-2">
            <b-button v-if="shrinkRules" variant="outline-success" size="sm" @click="shrink(false)" :disabled="busy.shrink">전체 rule 보기 <b-spinner v-if="busy.shrink" small></b-spinner></b-button>
            <b-button v-else variant="outline-success" size="sm" @click="shrink(true)" :disabled="busy.shrink">특수 rule만 보기 <b-spinner v-if="busy.shrink" small></b-spinner></b-button>
            <div class="pull-right">
              <b-button class="mr-2" variant="secondary" size="sm" @click="renamePage(p.page)">페이지명 수정</b-button>
              <b-button v-if="p.active" class="" variant="warning" size="sm" @click="activatePage(p.page, false)">현재 페이지를 비활성화</b-button>
              <b-button v-else class="" variant="success" size="sm" @click="activatePage(p.page, true)">현재 페이지를 활성화</b-button>
              <b-button class="ml-2" variant="danger" size="sm" @click="removePage(p.page)">현재 페이지 삭제</b-button>
            </div>
          </div>
          <b-card no-body class="mb-1" bg-variant="light">
            <b-card-body class="py-3">
              <b-row style="width:calc(100% - 100px)">
                <b-col cols="1" class="text-center">적용순서</b-col>
                <b-col cols="1" class="text-center">할인율</b-col>
                <b-col>카테고리</b-col>
                <b-col>브랜드</b-col>
                <b-col>시즌</b-col>
                <b-col>GoodsId(파트너상품코드)</b-col>
                <b-col>비고</b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <draggable v-model="rules[idx].data" @start="drag=true" @end="drag=false" @change="event=>reorder(event, null, p.page)">
            <div v-for="i in rules[idx].data" v-if="i && (!shrinkRules || !isTableRule(i._org))" :key="i.id">
              <b-card no-body class="mb-1">
                <b-card-body class="py-2">
                  <div class="d-flex align-items-center">
                    <b-row v-if="i.direct" style="width:calc(100% - 100px)">
                      <b-col cols="1" class="text-center">{{i.order}}</b-col>
                      <b-col cols="9">직접 지정 - {{i.direct}}</b-col>
                      <b-col>{{i.desc}}</b-col>
                    </b-row>
                    <b-row v-else style="width:calc(100% - 100px)">
                      <b-col cols="1" class="text-center">{{i.order}}</b-col>
                      <b-col cols="1" class="text-center"><b-badge variant="success" pill>{{i.rate}} %</b-badge></b-col>
                      <b-col>{{i.category}}</b-col>
                      <b-col>{{i.brand_name}}</b-col>
                      <b-col>{{i.launch_date}}</b-col>
                      <b-col>{{i.goods_id}}</b-col>
                      <b-col>{{i.desc}}</b-col>
                    </b-row>

                    <div class="card-header-actions ml-auto">
                      <b-link class="card-header-action btn-minimize" v-b-toggle="`collapse`+i.id">
                        <i class="icon-arrow-down"></i>
                      </b-link>
                      <b-link href="#" class="card-header-action btn-close text-danger" @click="removeDiscountRule(i, selectedShop.shop_id)">
                        <i class="icon-close"></i>
                      </b-link>
                    </div>
                  </div>
                  <b-collapse :id="`collapse${i.id}`">
                    <div v-html="i._html" class="py-2"></div>
                  </b-collapse>
                </b-card-body>
              </b-card>
            </div>
          </draggable>
        </b-tab>
        <template v-slot:empty>
          <div class="text-center">
            SHOP 할인율규칙이 없습니다. + 버튼을 눌러서 추가해주세요.
          </div>
        </template>
      </b-tabs>
    </div>
  </div>
</template>
<style scoped>
@import '~handsontable/dist/handsontable.full.css';

.drop {
  border: 2px dashed #bbb;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  cursor: pointer;
  color: #bbb;
}
.drop table th, .drop table td {
  padding: 0.25rem;
}
.drop .title {
  font: 20pt bold,"Vollkorn";
}
</style>
<script>
import cTable from '@/views/TableBase.vue'
import vSelect from 'vue-select'
import {getMeta, getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down, readXlsx} from '@/shared/impexp'
import draggable from 'vuedraggable'
import {DELIVER_BOARD_COLUMNS, DISCOUNT_RULE_COLUMNS} from 'balaan_constants'
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import store from '@/store';
const {state:S, getters:{R}} = store;

export default {
  name: 'Discount',
  title: '할인율 설정',
  components: {cTable, vSelect, draggable, HotTable},
  data() {
    return {
      meta: null,
      stat: null,
      shop: [],
      category: [],
      brand: [],
      season: [],
      shopMap: {},
      categoryMap: {},
      brandMap: {},
      seasonMap: {},
      seasonBrandMap: {},
      selectedShop: null,
      havingCategoryOnly: false,
      havingBrandOnly: true,
      havingSeasonOnly: false,
      globalSeason: [],
      defaultForm: {
        category: [],
        brand: [],
        season: [],
        goods_id: '',
        rate: 5,
        desc: '',
        goods_direct: '',
        desc_direct: '',
      },
      form: {},
      formIndex: 0,
      rules: [],
      globalRules: [],
      shrinkRules: true,
      pageIndex: 0,
      globalPageIndex: 0,
      items_detail: [],
      fields: [
        {key:'category', label:'카테고리'},
        {key:'brand', label:'브랜드'},
        {key:'season', label:'시즌'},
        {key:'rate', label:'할인율'},
        {key:'desc', label:'비고'},
        {key:'_dt', label:'설정일시'},
        {key:'_actions', label:'Actions', buttons: [{label:'상세', event:'show_details'}, {label:'제거', variant:'danger', event:'remove'}]},
      ],
      fields_detail: ['shop_id', 'img', {key:'id', label:'Goods Id / Sku Id'}, 'goodsNo', 'launch_date', 'category', {key:'goods', label:'Brand / Name'}, 'consumer', 'price', 'discount_rate'],
      perPage: 1000,
      busy: {search:false, get:false, shrink:false},
      modal: false,
      helpModal: false,
      xlsxData: [],
      collapse: false,
      hotSettings: {},
      hotSettingsBase: {
        data: [],
        // data: Handsontable.helper.createSpreadsheetData(6, 10),
        colHeaders: ['Brand'],
        columns: [
          {
            data: 'brand_name',
            readOnly: true
          },
          // {
          //   data: 'cnt',
          //   readOnly: true
          // },
          // ...'WCL,WBA,WSH,WAC,WJW,MCL,MBA,MSH,MAC,MJW'.split(',').map(e=>({data:e})),
        ],
        colWidths: [300],
        rowHeaders: true,
        height: 500,
        // rowHeaderWidth: 300,
        licenseKey: 'non-commercial-and-evaluation',
      },
      hasStockRenderer(instance, td, row, col, prop, value, cellProperties) {
        console.log(instance);
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        td.style.backgroundColor = 'yellow';
      }
    }
  },
  async created() {
    await this.init();

    const id = this.$route.params.id;
    if (id) {
      this.selectedShop = this.shop.filter(e=>e.shop_id === +id)[0];
      // if (this.selectedShop) this.getShop();
    }

    this.form = utils.clone(this.defaultForm);
  },
  watch: {
    pageIndex(v) {
      if (v < 0) return;
      this.selectPage(v);
    }
  },
  methods: {
    async init() {
      let meta = await getMeta('shop');
      if (!meta) return;
      let j = await getJson('/price/discountPage');
      if (!j) return;
      let {pages, active} = j, pageMap = {}, activeMap = {};
      pages.forEach(e=>pageMap[e._id] = e);
      active.forEach(e=>activeMap[e.key] = e.data_cnt);

      this.shop = meta.shop.filter(e=>e.use_yn==='y').sort((a,b)=>a.shop_id-b.shop_id);
      this.shop.forEach(s=>{
        s.value = s.boutique;
        if (!pageMap[s.shop_id]) {
          s.label = `${s.shop_id}. ${s.boutique} (규칙 없음) `;
        } else {
          s.label = `${s.shop_id}. ${s.boutique} (총 ${(pageMap[s.shop_id] || {}).cnt || 0} 개 페이지${activeMap[s.shop_id] ? ', 활성화된 규칙 있음' : ''}) `;
        }
        s.pageCnt = (pageMap[s.shop_id] || {}).cnt || 0;
        s.ruleCnt = (pageMap[s.shop_id] || {}).sum || 0;
        this.shopMap[s.shop_id] = s;
      });
    },
    resetForm() {
      this.form = utils.clone(this.defaultForm);
    },
    async getShop() {
      if (!this.selectedShop) {
        return;
      }
      this.brand = [];
      this.category = [];
      this.season = [];

      this.busy.search = true;
      let stat = this.stat = await getJson('/goods/shopStat/'+this.selectedShop.shop_id);
      if (stat) {
        this.setStat();
        this.resetForm();
      }
      this.busy.search = false;
      this.getDiscountRule(this.selectedShop.shop_id);
    },
    setStat() {
      /**
       * shop 의 통계에 따라 카테고리, 브랜드, 시즌을 갯수와 함께 나타낸다.
       * 카테고리, 브랜드는 meta가 있기에 shop 통계에 없는 것도 보여줄 수 있다(앞으로 들어올 상품에 대비)
       */
      if (!this.stat) return;
      let stat = this.stat;
      stat.goodsCount = 0;
      this.brandMap = {};
      this.categoryMap = {};
      this.seasonMap = {};
      this.seasonBrandMap = {};
      this.hotSettings = {};
      stat.stat.forEach(e=>{
        let b = this.brandMap[e._id.brand_name] = this.brandMap[e._id.brand_name] || {value:e._id.brand_name, cnt:0};
        let c = this.categoryMap[e._id.category] = this.categoryMap[e._id.category] || {value:e._id.category, cnt:0};
        let s = this.seasonMap[e._id.launch_date] = this.seasonMap[e._id.launch_date] || {value:e._id.launch_date, cnt:0};
        b.cnt += e.cnt;
        c.cnt += e.cnt;
        s.cnt += e.cnt;
        stat.goodsCount += e.cnt;
        let sb = this.seasonBrandMap[e._id.launch_date] = this.seasonBrandMap[e._id.launch_date] || {};
        let bc = sb[e._id.brand_name] = sb[e._id.brand_name] || {brand_name:e._id.brand_name};
        bc[e._id.category] = (bc[e._id.category] || 0) + e.cnt;
      });
      this.brand = Object.values(this.brandMap).map(e=>({label:`${e.value} : ${e.cnt} 개`, ...e}));
      this.brand.sort((a,b)=>a.value.localeCompare(b.value));
      this.category = Object.values(this.categoryMap).map(e=>({label:`${e.value} : ${e.cnt} 개`, ...e}));
      this.category.sort((a,b)=>a.value.localeCompare(b.value));
      this.season = Object.values(this.seasonMap).map(e=>{
        let hs = this.hotSettings[e.value] = utils.clone(this.hotSettingsBase);
        'WCL,WBA,WSH,WAC,WJW,MCL,MBA,MSH,MAC,MJW'.split(',').forEach(e=>{
          if (this.categoryMap[e]) {
            hs.colHeaders.push(e);
            hs.columns.push({data:e});
          }
        });
        return {label:`${e.value} : ${e.cnt} 개`, ...e};
      });
      this.season.sort((a,b)=>b.value.localeCompare(a.value));

      // this.category = stat.category.map(e=>{
      //   stat.goodsCount += e.cnt;
      //   return {label:`${e._id} : ${e.cnt} 개`, value:e._id, cnt:e.cnt};
      // });
      // this.category.sort((a,b)=>a.value.localeCompare(b.value));
      //
      // this.brandMap = {};
      // this.brand = stat.brand_name.map(e=>{
      //   return this.brandMap[e._id] = {label:`${e._id} : ${e.cnt} 개`, value:e._id, cnt:e.cnt};
      // });
      // this.brand.sort((a,b)=>a.value.localeCompare(b.value));
      //
      // this.season = stat.launch_date.map(e=>{
      //   this.hotSettings[e._id] = utils.clone(this.hotSettingsBase);
      //   return {label:`${e._id} : ${e.cnt} 개`, value:e._id, cnt:e.cnt};
      // });
      // this.season.sort((a,b)=>a.value.localeCompare(b.value));
    },
    async getDiscountRule(shop_id, pageIdx) {
      this.busy.get = true;
      let j = await postJson('/price/discountRule', {shop_id:shop_id === 'global' && this.selectedShop ? this.selectedShop.shop_id : shop_id});
      if (!j) return;
      let ruleLambda = (e,i)=>{
        let category = '-', brand_name = '-', launch_date = '-', goods_id = '-', _html = '', direct = '';
        if (e.category && e.category.length) {
          let c = this.categoryMap[e.category[0]];
          let label = c ? `${c.value}` : e.category[0];
          if (e.category.length > 1) {
            category = `${label} 외 ${e.category.length - 1} 개`;
          } else {
            category = label;
          }
          _html += `적용 카테고리 :<br/>&nbsp;&nbsp;&nbsp;${e.category.map(e=>{
            let c = this.categoryMap[e];
            if (!c) return `<span class="badge badge-light">${e}</span>`;
            return `<span class="badge badge-light">${c.value}</span>`
          }).join('&nbsp;')}<br/>`
        } else {
          _html += '적용 카테고리 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-success">전체</span><br/>';
        }
        if (e.brand_name && e.brand_name.length) {
          let b = this.brandMap[e.brand_name[0]]; // 기존에 존재했던 브랜드에 할인율을 지정했다가 해당 상품이 사라지면 b는 없을수도 있다.
          let label = `${e.brand_name[0]}`;
          if (e.brand_name.length > 1) {
            brand_name = `${label} 외 ${e.brand_name.length - 1} 개`;
          } else {
            brand_name = label;
          }
          _html += `적용 브랜드 :<br/>&nbsp;&nbsp;&nbsp;${e.brand_name.map(e=>{
            // let b = this.brandMap[e];
            return `<span class="badge badge-light">${e}</span>`
          }).join('&nbsp;')}<br/>`
        } else {
          _html += '적용 브랜드 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-success">전체</span><br/>';
        }
        if (e.launch_date && e.launch_date.length) {
          launch_date = `${e.launch_date[0]}` + (e.launch_date.length > 1 ? ` 외 ${e.launch_date.length - 1} 개` : '');
          _html += `적용 시즌 :<br/>&nbsp;&nbsp;&nbsp;${e.launch_date.map(e=>`<span class="badge badge-light">${e}</span>`).join('&nbsp;')}<br/>`;
        } else {
          _html += '적용 시즌 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-success">전체</span><br/>';
        }
        if (e.goods_id && e.goods_id.length) {
          goods_id = `${e.goods_id[0]}` + (e.goods_id.length > 1 ? ` 외 ${e.goods_id.length - 1} 개` : '');
          _html += `적용 goods_id :<br/>&nbsp;&nbsp;&nbsp;${e.goods_id.map(e=>`<span class="badge badge-light">${e}</span>`).join('&nbsp;')}<br/>`;
        }
        if (e.direct) {
          let kv = Object.entries(e.direct), size = kv.length;
          direct = kv.slice(0, 3).map(([k,v])=>`${k}: ${utils.comma(v)} ${v > 50 ? '원' : '%'}`).join(', ') + (size > 3 ? ` 외 ${size - 3} 개` : '');
          _html = `총 ${size} 개 상품${size>100 ? '(최초 100 개만 보여집니다)' : ''}<br/>`
            + kv.slice(0, 100).map(([k,v])=>`${k}: ${utils.comma(v)} ${v > 50 ? '원' : '%'}`).join('<br/>') + '<br/><br/>';
        }
        if (e.desc) {
          _html += '비고 :<br/>&nbsp;&nbsp;&nbsp;' + e.desc + '<br/>';
        }
        _html += `설정일시 :<br/>&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${e._dt}</span><span class="badge badge-success"> ${e._name}</span>`;
        return {id:i, order:i+1, category, brand_name, launch_date, goods_id, rate:e.rate, desc:e.desc, _dt:e._dt, _html, direct, _org:e};
      };
      this.rules = j.list.map((p,i)=>{ return {...p, data:p.data.map(ruleLambda)};});
      this.pageIndex = pageIdx != null ? pageIdx : (this.rules.length > this.pageIndex ? this.pageIndex : (this.rules.length ? 0 : -1));

      // 현재 페이지의 룰대로 치환한다.
      // 시즌과 brand_name 조합으로 map 을 먼저 생성
      this.selectPage(this.pageIndex);
      this.$forceUpdate();
      this.busy.get = false;
    },
    selectPage(idx) {
      let seasonBrandMap = {};
      Object.keys(this.hotSettings).forEach(k=>{
        /*
         * rule 에서 hotTable 로 탑재한다.
         */
        seasonBrandMap[k] = {};
        this.rules[idx] && this.rules[idx].data.map(e=>e._org).filter(e=>e._ht && e.launch_date && ~e.launch_date.indexOf(k) && e.brand_name).forEach(e=>{
          e.brand_name.forEach(b=>{
            if (e.category) {
              let m = seasonBrandMap[k][`${b}`] = seasonBrandMap[k][`${b}`] || {brand_name:b};
              e.category.forEach(c=> {
                m[c] = e.rate;
              });// [s][b][c] = rate, [s][b] = {wac:20}
            }
          });
        });
        // console.log(k, seasonBrandMap[k], this.rules[idx].data.map(e=>e._org));
        // this.hotSettings[k].data = this.brand.map(e=>seasonBrandMap[k][e.value] || {brand_name:e.value});
        if (this.$refs[`hotTable_${k}`][0].hotInstance) {
          this.$refs[`hotTable_${k}`][0].hotInstance.loadData(this.brand.map(e=>seasonBrandMap[k][e.value] || (this.seasonBrandMap[k][e.value] ? {brand_name:e.value} : null)).filter(e=>e));
        } else {
          this.hotSettings[k].data.splice(0,this.hotSettings[k].data.length);
          this.brand.map(e=>seasonBrandMap[k][e.value] || (this.seasonBrandMap[k][e.value] ? {brand_name:e.value} : null)).filter(e=>e).forEach(e=>this.hotSettings[k].data.push(e));
        }
      });
    },
    async reorder(event, shop_id, page) {
      // let rules = (shop_id === 'global' ? this.globalRules : this.rules).filter(p=>p.page === page)[0].data;
      // rules.forEach((e,i)=>{e.order = i+1});
      // let j = await postJson('/price/setDiscountRule', {type:'reorder', shop_id:shop_id || this.selectedShop.shop_id, rule:rules.map(e=>e._org), page});
      let j = await postJson('/price/setDiscountRule', {type:'reorder', shop_id:shop_id || this.selectedShop.shop_id, page, oldIndex: event.moved.oldIndex, newIndex: event.moved.newIndex, rule: true});
    },
    async addDiscountRule(shop_id) {
      if (!(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex])) return;
      let rule = {...this.form};
      if (this.formIndex === 0) {
        Object.entries(rule).forEach(([k,v])=>{
          if (k === 'goods_id') {
            rule[k] = v = v && v.trim() ? v.split(/\r?\n/g).map(e=>e.trim()).filter(e=>e) : null;
            if (v === null) delete rule[k];
          } else if (utils.typeOf(v) === 'array') {
            if (!v.length) delete rule[k];
            else rule[k] = rule[k].map(e => e.value);
            if (k === 'brand') {
              rule.brand_name = rule.brand;
              delete rule.brand;
            }
            if (k === 'season') {
              rule.launch_date = rule.season;
              delete rule.season;
            }
          } else if (k.endsWith('_direct')) {
            delete rule[k];
          }
        });
        let j = await postJson('/price/setDiscountRule', {type:'add', shop_id, rule, page:(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex]).page});
        if (!j) return;
      } else {
        let direct = {};
        rule.goods_direct.split(/\r?\n/).filter(e=>e.trim()).forEach(e=>{
          let [goods_id, price] = e.trim().split(/\s/).map(e=>+e.replace(/,/g, ''));
          direct[goods_id] = price;
        });
        let j = await postJson('/price/setDiscountRule', {type:'add', shop_id, rule: {direct, desc: rule.desc_direct}, page:(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex]).page});
        if (!j) return;
      }
      this.getDiscountRule(shop_id);
    },
    async removeDiscountRule(row, shop_id) {
      if (!confirm('규칙을 정말로 삭제하시겠습니까?')) return;
      let obj = JSON.parse(JSON.stringify(row._org));
      let j = await postJson('/price/setDiscountRule', {type:'remove', shop_id, rule:obj, page:(shop_id === 'global' ? this.globalRules[this.globalPageIndex] : this.rules[this.pageIndex]).page});
      if (!j) return;
      this.getDiscountRule(shop_id);
    },
    shrink(b) {
      this.busy.shrink = true;
      setTimeout(_=>{
        this.shrinkRules = b;
        this.busy.shrink = false;
      }, 10);
    },
    isTableRule(rule) {
      // hotTable 에서 생성된 일반적인 형태의 rule 인지 확인(너무 많을 경우 생략하기 위함)
      // launch_date, brand_name, category, rate 만 있어야 한다.
      // let keys = "launch_date,brand_name,category,rate".split(',');
      // let hasOtherKey = !Object.keys(rule).filter(k=>!(~keys.indexOf(k) || k[0] === '_')).length;
      // return hasOtherKey;
      return rule._ht;
    },
    async newPage() {
      let maxPage = this.rules.map(e=>e.page).reduce((a,b)=>a>b?a:b, 0);
      let j = await postJson('/price/addDiscountPage', {shop_id:this.selectedShop.shop_id, page:maxPage + 1});
      if (!j) return;
      this.rules.push({page:maxPage + 1, data:[], data_cnt:0});
    },
    async renamePage(page) {
      let name = prompt('페이지명을 입력해주세요');
      if (name == null) return;
      let j = await postJson('/price/renameDiscountPage', {shop_id:this.selectedShop.shop_id, name, page});
      if (!j) return;
      this.getDiscountRule(this.selectedShop.shop_id);
    },
    async activatePage(page, active) {
      if (!confirm(`${page} page 의 규칙을 ${active ? '' : '비'}활성화 하시겠습니까?`)) return;
      let j = await postJson('/price/activateDiscountPage', {shop_id:this.selectedShop.shop_id, active, page});
      if (!j) return;
      this.getDiscountRule(this.selectedShop.shop_id);
    },
    async removePage(page) {
      if (!confirm(`${page} page를 삭제하시겠습니까?`)) return;
      let j = await postJson('/price/removeDiscountPage', {shop_id:this.selectedShop.shop_id, page});
      if (!j) return;
      await this.getDiscountRule(this.selectedShop.shop_id);
      if (this.rules.length === 0) {
        // shops 를 갱신하여 shortcut 버튼을 없앤다.
        this.selectedShop = null;
        this.init();
      }
    },
    async saveTables() {
      /**
       * 현재 페이지에서 _ht 가 있는 데이터를 전부 제거하고 table 데이터를 넣는다.
       */
      let rule = this.rules[this.pageIndex];
      if (!rule) return alert('활성화된 규칙 페이지가 없습니다');

      let data = rule.data.map(e=>e._org).filter(e=>!e._ht);

      Object.keys(this.hotSettings).forEach(k=>{
        let rows = this.$refs[`hotTable_${k}`][0].hotInstance.getSourceData();
        rows.forEach(row=>{ // row = {brand_name, WCL..}, 하나라도 값이 있는 경우만 포함한다.
          let rateGroup = {}; // 할인율별로 묶는다
          'WCL,WBA,WSH,WAC,WJW,MCL,MBA,MSH,MAC,MJW'.split(',').filter(e=>row[e] != null).forEach(cate=>{
            let rate = row[cate];
            let r = rateGroup[rate] = rateGroup[rate] || {category:[], rate};
            r.category.push(cate);
          });
          Object.keys(rateGroup).forEach(rate=>{
            let group = rateGroup[rate];
            data.push({launch_date:[k], brand_name:[row.brand_name], category:group.category, rate:+rate, _ht:true})
          })
        })
      });
      let j = await postJson('/price/setDiscountPage', {shop_id:this.selectedShop.shop_id, rule:data, page:rule.page});
      if (j) {
        await this.getDiscountRule(this.selectedShop.shop_id, this.pageIndex);
        alert('저장되었습니다');
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleFile(event) {
      let file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file);
      this.uploadXlsxData(event.target.dataset.type, headers, rows);
    },
    xlsxSample(type) {
      if (type === 'rule') {
        let cols = 'category,brand_name,season,goods_id,rate'.split(',');
        down([{category:'009003,009004', brand_name:'333,4444', rate:5}, {goods_id:'2334142', rate:7}], cols, cols, `RuleSample`, 'xlsx');
      } else if (type === 'price') {
        let cols = 'goods_id,price'.split(',');
        down([{goods_id:'2213459', price:550000}, {goods_id:'2334142', price:1070000}], cols, cols, `PriceSample`, 'xlsx');
      }
    },
    setTimeout(f, t) {
      setTimeout(f, t);
    }
  }
}
</script>
